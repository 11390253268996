.snackbarLayout > div {
    justify-content: center;
    font-family: "Tw Cen MT", sans-serif;
    font-size: 1.3em;
}

.splashScreen {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(#ac9fcb, #e9e4f0);
    position: absolute;
}

.splashScreenIconGrid {
    top: 20%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
}

.splashScreenIconGridFlexbox {
    position: relative;
    display: flex;
    flex-direction: column;
    transform: scale(2);
}

.splashScreenIconsHorizontal {
    display: flex;
    flex-direction: row;

}

.splashScreenIconsHorizontal > * {
    margin: 0.2em;

}

.splashScreenTitle {
    font-size: 14vmin;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    text-align: center;
    font-family: "OrangeSlices", sans-serif;
    background: -webkit-linear-gradient(45deg, #d7a1c6, #b187a9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.splashScreenTitle2 {
    font-size: 6vmin;
    position: absolute;
    left: 0;
    right: 0;
    top: 65%;
    text-align: center;
    font-family: "OrangeSlices", sans-serif;
    background: -webkit-linear-gradient(45deg, #47598a, #a17bc8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.notesInfoSection {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    margin: 3em 0;
    pointer-events: none;
}

.notesInfoSection > span {
    width: 100%;
    text-align: center;
    color: white;
    font-family: "Tw Cen MT", sans-serif;
    font-size: 2em;
}

.notesInfoSection > span:nth-child(even) {
    margin-top: 1.5em
}

.topDrawerExpanderCue {
    position: absolute;
    top: 0;
    left: 42.5%;
    width: 15%;
    height: 1.3em;
    background: linear-gradient(180deg, #2e4054, #425f72);
    text-align: center;
    border-radius: 0 0 15px 15px;
    box-shadow: -2px -2px 14px 0px rgba(0, 0, 0, 0.63);
    color: ghostwhite;
}

.bottomDrawerExpanderCue {
    position: absolute;
    bottom: 0;
    left: 42.5%;
    width: 15%;
    height: 1.3em;
    background: linear-gradient(0deg, #2e4054, #425f72);
    text-align: center;
    border-radius: 15px 15px 0 0;
    box-shadow: 2px 2px 14px 0px rgba(0, 0, 0, 0.63);
    color: ghostwhite;
}